//@ts-ignore
//@ts-ignore
import defo from "@icelab/defo";
import camilleSignatureJpeg from "url:~./assets/images/camille-signature.jpg";
import camilleUnbridelyJpeg from "url:~./assets/images/camille-unbridely.jpg";
import camilleUnbridelyWebp from "url:~./assets/images/camille-unbridely.webp";
import honeyFundPng from "url:~./assets/images/honey-fund.png";
import huffpostPng from "url:~./assets/images/huffpost.png";
import joyPng from "url:~./assets/images/joy.png";
import personDemonstratingSvg from "url:~./assets/images/person-demonstrating.svg";
import personHangingSvg from "url:~./assets/images/person-hanging.svg";
import personHappySvg from "url:~./assets/images/person-happy.svg";
import personMegaphoneSvg from "url:~./assets/images/person-megaphone.svg";
import personSearchingSvg from "url:~./assets/images/person-searching.svg";
import relaxSvg from "url:~./assets/images/relax.svg";
import supplierHeroWideJpeg from "url:~./assets/images/supplier_hero-wide.jpg";
import supplierHeroWideWebp from "url:~./assets/images/supplier_hero-wide.webp";
import supplierHeroJpeg from "url:~./assets/images/supplier_hero.jpg";
import supplierHeroWebp from "url:~./assets/images/supplier_hero.webp";
import unbridelyHeroJpeg from "url:~./assets/images/unbridely_portrait_web.jpg";
import unbridelyHeroWebp from "url:~./assets/images/unbridely_portrait_web.webp";

import { Elm as ElmJs } from "./app/Main.elm";
import { Elm } from "./app/Main/index";

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle("show", entry.isIntersecting);
      if (entry.isIntersecting) observer.unobserve(entry.target);
    });
  },
  { threshold: 0.5 }
);

type MediaQueryStore = {
  [key: string]: MediaQueryList;
};

const mediaQueryListeners: MediaQueryStore = {};

const touchDevice = matchMedia("(pointer:coarse)").matches;

function handleFragmentScroll(id: string) {
  return function () {
    const scrollElem = document.getElementById(id);
    if (scrollElem) {
      scrollElem.scrollIntoView(true);
    }
  };
}

const defoViews = {
  fragmentScroll: (el: HTMLElement, id: string) => {
    el.addEventListener("click", handleFragmentScroll(id));

    return {
      destroy: () => {
        el.removeEventListener("click", handleFragmentScroll(id));
      },
    };
  },
  showIn: (el: HTMLElement) => {
    observer.observe(el);
    return {};
  },
};

function minWidthMediaListener(minWidth: number, initializedApp: Elm.Main.App) {
  return (mql: MediaQueryListEvent) => {
    if (mql.matches) {
      // window is at least min width
      initializedApp.ports.mediaListenerAboveMinWidthNotifier.send(minWidth);
    } else {
      // window is below min width
      initializedApp.ports.mediaListenerBelowMinWidthNotifier.send(minWidth);
    }
  };
}

function setupPorts(initializedApp: Elm.Main.App) {
  initializedApp.ports.setupMediaListenerMinWidth.subscribe(
    (minWidth: number) => {
      const mql: MediaQueryList = window.matchMedia(
        `(min-width: ${minWidth}px)`
      );
      // return early if listener already exists
      if (mediaQueryListeners[minWidth]) return;

      Object.assign<MediaQueryStore, MediaQueryStore>(mediaQueryListeners, {
        [minWidth]: mql,
      });
      mediaQueryListeners[minWidth].onchange = minWidthMediaListener(
        minWidth,
        initializedApp
      );
    }
  );
}

const app: Elm.Main.App = ElmJs.Main.init({
  node: document.querySelector("main"),
  flags: {
    touchDevice,
    unbridelyHeroJpeg,
    unbridelyHeroWebp,
    supplierHeroJpeg,
    supplierHeroWebp,
    supplierHeroWideJpeg,
    supplierHeroWideWebp,
    relaxSvg,
    personDemonstratingSvg,
    personHangingSvg,
    personHappySvg,
    personMegaphoneSvg,
    personSearchingSvg,
    camilleUnbridelyJpeg,
    camilleUnbridelyWebp,
    camilleSignatureJpeg,
    huffpostPng,
    honeyFundPng,
    joyPng,
  },
});

defo({ views: defoViews });
setupPorts(app);
