module Main exposing (view)

import AppDecoders exposing (flagsDecoder)
import Browser
import Browser.Navigation as Nav
import Context
import Effect
import Html.Styled as Styled
import Json.Decode as Decode
import Json.Encode as Encode
import Page
import Pages.Admin.Enquiries as AdminEnquiriesPage
import Pages.Admin.Home as AdminHomePage
import Pages.Admin.SignIn as AdminSignInPage
import Pages.Admin.Suppliers as AdminSuppliersPage
import Pages.AppError as AppErrorPage
import Pages.GoogleAuthCallback as GoogleAuthCallbackPage
import Pages.NotFound as NotFoundPage
import Pages.Privacy as PrivacyPage
import Pages.Public.PublicHome as PublicHomePage
import Pages.Supplier.Settings as SupplierSettingsPage
import Pages.Supplier.Setup as SupplierSetupPage
import Pages.Supplier.SignIn as SupplierSignInPage
import Pages.Supplier.SignUp as SupplierSignUpPage
import Pages.Supplier.Tasks as SupplierTasksPage
import Route
import Session as Session
import Url exposing (Url)


type Msg
    = ClickedLink Browser.UrlRequest
    | ChangedUrl Url
    | GotPublicHomeMsg PublicHomePage.Msg
    | GotAdminHomeMsg AdminHomePage.Msg
    | GotAdminEnquiriesMsg AdminEnquiriesPage.Msg
    | GotAdminSignInMsg AdminSignInPage.Msg
    | GotAdminSuppliersMsg AdminSuppliersPage.Msg
    | GotSupplierSignInMsg SupplierSignInPage.Msg
    | GotSupplierSignUpMsg SupplierSignUpPage.Msg
    | GotSupplierSetupMsg SupplierSetupPage.Msg
    | GotSupplierTasksMsg SupplierTasksPage.Msg
    | GotSupplierSettingsMsg SupplierSettingsPage.Msg
    | GotAuthCallbackMsg GoogleAuthCallbackPage.Msg
    | SessionAuthorized (Maybe (Cmd Msg)) (Result Session.SessionError Session.SessionSuccess)
    | SessionDestroyed (Result Session.SessionError Session.SessionSuccess)
    | SessionGranted (Result Session.SessionError Session.SessionSuccess)


type AppInitModel
    = PublicHome PublicHomePage.Model
    | AdminHome AdminHomePage.Model
    | AdminEnquiries AdminEnquiriesPage.Model
    | AdminSignIn AdminSignInPage.Model
    | AdminSuppliers AdminSuppliersPage.Model
    | SupplierSignIn SupplierSignInPage.Model
    | SupplierSignUp SupplierSignUpPage.Model
    | SupplierSetup SupplierSetupPage.Model
    | SupplierTasks SupplierTasksPage.Model
    | SupplierSettings SupplierSettingsPage.Model
    | GoogleAuthCallback GoogleAuthCallbackPage.Model
    | Privacy Context.Context
    | NotFound Context.Context


type Model
    = AppInit AppInitModel
    | AppError


init : Encode.Value -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        route =
            Route.fromUrl url

        decodedFlags =
            Decode.decodeValue flagsDecoder flags

        -- Some routes we can eagerly sign in the user on page load.
        -- Otherwise it's set as the default, Guest.
        ( sessionCmd, session ) =
            case route of
                Just r ->
                    case r of
                        Route.PublicHome ->
                            ( Cmd.none, Session.guest )

                        Route.AdminHome ->
                            ( Cmd.none, Session.guest )

                        Route.AdminEnquiries ->
                            ( Cmd.none, Session.guest )

                        Route.AdminSignIn ->
                            ( Cmd.none, Session.guest )

                        Route.AdminSuppliers ->
                            ( Cmd.none, Session.guest )

                        Route.AuthGoogleCallback _ ->
                            ( Cmd.none, Session.guest )

                        Route.SupplierRoute Route.SupplierSignIn ->
                            ( Cmd.none, Session.guest )

                        Route.SupplierRoute Route.SupplierSignUp ->
                            ( Cmd.none, Session.guest )

                        Route.SupplierRoute Route.SupplierSetup ->
                            Session.refresh (SessionAuthorized Nothing)

                        Route.SupplierRoute (Route.SupplierTasks _) ->
                            Session.refresh (SessionAuthorized Nothing)

                        Route.SupplierRoute (Route.SupplierSettings _ _) ->
                            Session.refresh (SessionAuthorized Nothing)

                        Route.Privacy ->
                            ( Cmd.none, Session.guest )

                _ ->
                    ( Cmd.none, Session.guest )
    in
    case decodedFlags of
        Ok data ->
            changeRouteTo route
                (Context.toContext data navKey session)
                |> Tuple.mapSecond
                    (\cmds -> Cmd.batch [ cmds, sessionCmd ])
                |> Tuple.mapFirst
                    (\mdl -> AppInit mdl)

        Err _ ->
            ( AppError, Cmd.none )


changeRouteTo : Maybe Route.Route -> Context.Context -> ( AppInitModel, Cmd Msg )
changeRouteTo maybeRoute context =
    case maybeRoute of
        Nothing ->
            ( NotFound context, Cmd.none )

        Just Route.PublicHome ->
            let
                publicHomeModel =
                    PublicHomePage.init context
            in
            ( PublicHome publicHomeModel, Cmd.none )

        Just Route.AdminHome ->
            let
                ( adminHomeModel, adminHomeCmd ) =
                    AdminHomePage.init context
            in
            ( AdminHome adminHomeModel, Cmd.map GotAdminHomeMsg adminHomeCmd )

        Just Route.AdminEnquiries ->
            let
                ( adminEnquiriesModel, adminEnquiriesCmd ) =
                    AdminEnquiriesPage.init context
            in
            ( AdminEnquiries adminEnquiriesModel, Cmd.map GotAdminEnquiriesMsg adminEnquiriesCmd )

        Just Route.AdminSignIn ->
            let
                ( signInModel, signInCmd ) =
                    AdminSignInPage.init context
            in
            ( AdminSignIn signInModel, Cmd.map GotAdminSignInMsg signInCmd )

        Just Route.AdminSuppliers ->
            let
                ( adminSuppliersModel, adminSuppliersCmd ) =
                    AdminSuppliersPage.init context
            in
            ( AdminSuppliers adminSuppliersModel, Cmd.map GotAdminSuppliersMsg adminSuppliersCmd )

        Just (Route.SupplierRoute Route.SupplierSignIn) ->
            let
                ( signInModel, signInCmd ) =
                    SupplierSignInPage.init context
            in
            ( SupplierSignIn signInModel, Cmd.map GotSupplierSignInMsg signInCmd )

        Just (Route.SupplierRoute Route.SupplierSignUp) ->
            let
                ( signUpModel, signUpCmd ) =
                    SupplierSignUpPage.init context
            in
            ( SupplierSignUp signUpModel, Cmd.map GotSupplierSignUpMsg signUpCmd )

        Just (Route.SupplierRoute Route.SupplierSetup) ->
            let
                ( setupModel, setupCmd ) =
                    SupplierSetupPage.init context
            in
            ( SupplierSetup setupModel, Cmd.map GotSupplierSetupMsg setupCmd )

        Just (Route.SupplierRoute (Route.SupplierTasks supplierId)) ->
            let
                ( tasksModel, tasksCmd ) =
                    SupplierTasksPage.init context supplierId
            in
            ( SupplierTasks tasksModel, Cmd.map GotSupplierTasksMsg tasksCmd )

        Just (Route.SupplierRoute (Route.SupplierSettings id setting)) ->
            let
                ( settingsModel, settingsCmd ) =
                    SupplierSettingsPage.init context id setting
            in
            ( SupplierSettings settingsModel, Cmd.map GotSupplierSettingsMsg settingsCmd )

        Just (Route.AuthGoogleCallback (Just callbackParams)) ->
            let
                ( sessionCmd, updatedSession ) =
                    case context.session of
                        Session.Guest ->
                            Session.authCallBack SessionGranted callbackParams

                        _ ->
                            ( Cmd.none, context.session )

                updatedContext =
                    Context.sessionToContext updatedSession context

                ( authCallbackModel, _ ) =
                    GoogleAuthCallbackPage.init updatedContext
            in
            ( GoogleAuthCallback authCallbackModel, sessionCmd )

        Just (Route.AuthGoogleCallback Nothing) ->
            ( NotFound context, Cmd.none )

        Just Route.Privacy ->
            ( Privacy context, Cmd.none )


main : Program Encode.Value Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = ClickedLink
        , onUrlChange = ChangedUrl
        }


subscriptions : Model -> Sub Msg
subscriptions model =
    case model of
        AppInit (PublicHome publicHomeModel) ->
            Sub.map GotPublicHomeMsg (PublicHomePage.subscriptions publicHomeModel)

        AppInit (AdminSignIn signInModel) ->
            Sub.map GotAdminSignInMsg (AdminSignInPage.subscriptions signInModel)

        AppInit (SupplierSignIn signInModel) ->
            Sub.map GotSupplierSignInMsg (SupplierSignInPage.subscriptions signInModel)

        AppInit (SupplierSignUp signupModel) ->
            Sub.map GotSupplierSignUpMsg (SupplierSignUpPage.subscriptions signupModel)

        AppInit (AdminSuppliers adminSuppliersModel) ->
            Sub.map GotAdminSuppliersMsg (AdminSuppliersPage.subscriptions adminSuppliersModel)

        AppInit (SupplierSettings settingsModel) ->
            Sub.map GotSupplierSettingsMsg (SupplierSettingsPage.subscriptions settingsModel)

        _ ->
            Sub.none


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case model of
        AppInit initiatedModel ->
            case ( msg, initiatedModel ) of
                ( GotPublicHomeMsg publicHomeMsg, PublicHome publicHomeModel ) ->
                    PublicHomePage.update publicHomeMsg publicHomeModel
                        |> Tuple.mapBoth
                            (AppInit << PublicHome)
                            (Cmd.map GotPublicHomeMsg)

                ( GotAdminHomeMsg adminHomeMsg, AdminHome adminHomeModel ) ->
                    AdminHomePage.update adminHomeMsg adminHomeModel |> updateWithEffect (AppInit << AdminHome) GotAdminHomeMsg adminHomePageEffectHandler

                ( GotAdminEnquiriesMsg adminEnquiriesMsg, AdminEnquiries adminEnquiriesModel ) ->
                    AdminEnquiriesPage.update adminEnquiriesMsg adminEnquiriesModel
                        |> updateWithEffect (AppInit << AdminEnquiries) GotAdminEnquiriesMsg adminEnquiriesPageEffectHandler

                ( GotAdminSuppliersMsg adminSuppliersMsg, AdminSuppliers adminSuppliersModel ) ->
                    AdminSuppliersPage.update adminSuppliersMsg adminSuppliersModel
                        |> updateWithEffect (AppInit << AdminSuppliers) GotAdminSuppliersMsg adminSuppliersPageEffectHandler

                ( GotAdminSignInMsg signInMsg, AdminSignIn signInModel ) ->
                    AdminSignInPage.update signInMsg signInModel
                        |> updateWithEffect (AppInit << AdminSignIn) GotAdminSignInMsg adminSignInPageEffectHandler

                ( GotSupplierSignInMsg signInMsg, SupplierSignIn signInModel ) ->
                    SupplierSignInPage.update signInMsg signInModel
                        |> updateWithEffect (AppInit << SupplierSignIn) GotSupplierSignInMsg supplierSignInPageEffectHandler

                ( GotSupplierSignUpMsg signUpMsg, SupplierSignUp signUpModel ) ->
                    SupplierSignUpPage.update signUpMsg signUpModel
                        |> updateWithEffect (AppInit << SupplierSignUp) GotSupplierSignUpMsg supplierSignUpPageEffectHandler

                ( GotSupplierSetupMsg setupMsg, SupplierSetup setupModel ) ->
                    SupplierSetupPage.update setupMsg setupModel
                        |> updateWithEffect (AppInit << SupplierSetup) GotSupplierSetupMsg supplierSetupEffectHandler

                ( GotSupplierSettingsMsg settingsMsg, SupplierSettings settingsModel ) ->
                    SupplierSettingsPage.update settingsMsg settingsModel
                        |> updateWithEffect (AppInit << SupplierSettings) GotSupplierSettingsMsg supplierSettingsEffectHandler

                ( GotSupplierTasksMsg tasksMsg, SupplierTasks tasksModel ) ->
                    SupplierTasksPage.update tasksMsg tasksModel
                        |> updateWithEffect (AppInit << SupplierTasks) GotSupplierTasksMsg supplierTasksEffectHandler

                ( GotAuthCallbackMsg googleAuthCallbackMsg, GoogleAuthCallback googleAuthModel ) ->
                    GoogleAuthCallbackPage.update googleAuthCallbackMsg googleAuthModel
                        |> updateWithEffect (AppInit << GoogleAuthCallback) GotAuthCallbackMsg googleAuthCallbackEffectHandler

                ( SessionAuthorized extraCmd result, mdl ) ->
                    let
                        ( sessionCmd, updatedSession ) =
                            Session.fromResult result modelContext.navKey

                        modelContext =
                            contextFromModel mdl

                        updatedModel =
                            Context.sessionToContext updatedSession |> updateModelContext initiatedModel

                        resolveExtraCmd =
                            case extraCmd of
                                Just cmd ->
                                    Result.map (\_ -> cmd) result |> Result.withDefault Cmd.none

                                _ ->
                                    Cmd.none
                    in
                    ( AppInit updatedModel, Cmd.batch [ sessionCmd, resolveExtraCmd ] )

                ( SessionDestroyed result, mdl ) ->
                    let
                        ( sessionCmd, updatedSession ) =
                            Session.fromResult result modelContext.navKey

                        modelContext =
                            contextFromModel mdl

                        updatedModel =
                            Context.sessionToContext updatedSession |> updateModelContext initiatedModel
                    in
                    ( AppInit updatedModel, sessionCmd )

                ( SessionGranted result, mdl ) ->
                    let
                        ( sessionCmd, updatedSession ) =
                            Session.fromResult result modelContext.navKey

                        modelContext =
                            contextFromModel mdl

                        updatedModel =
                            Context.sessionToContext updatedSession |> updateModelContext mdl
                    in
                    ( AppInit updatedModel, sessionCmd )

                ( ChangedUrl url, mdl ) ->
                    let
                        modelContext =
                            contextFromModel mdl

                        ( newRouteModel, newRouteCmds ) =
                            changeRouteTo (Route.fromUrl url) modelContext
                    in
                    ( AppInit newRouteModel, newRouteCmds )

                ( ClickedLink urlRequest, mdl ) ->
                    case urlRequest of
                        Browser.Internal url ->
                            let
                                modelContext =
                                    contextFromModel mdl
                            in
                            ( model, Cmd.batch [ Nav.pushUrl modelContext.navKey (Url.toString url) ] )

                        Browser.External str ->
                            ( model, Nav.load str )

                _ ->
                    ( model, Cmd.none )

        AppError ->
            ( model, Cmd.none )


updateModelContext : AppInitModel -> (Context.Context -> Context.Context) -> AppInitModel
updateModelContext model contextUpdater =
    case model of
        PublicHome _ ->
            model

        AdminHome adminHomeModel ->
            AdminHome <| AdminHomePage.updateModelContext adminHomeModel contextUpdater

        AdminEnquiries adminEnquiriesModel ->
            AdminEnquiries <| AdminEnquiriesPage.updateModelContext adminEnquiriesModel contextUpdater

        AdminSignIn signInModel ->
            AdminSignIn <| AdminSignInPage.updateModelContext signInModel contextUpdater

        AdminSuppliers adminSuppliersModel ->
            AdminSuppliers <| AdminSuppliersPage.updateModelContext adminSuppliersModel contextUpdater

        SupplierSignIn signInModel ->
            SupplierSignIn <| SupplierSignInPage.updateModelContext signInModel contextUpdater

        SupplierSignUp signUpModel ->
            SupplierSignUp <| SupplierSignUpPage.updateModelContext signUpModel contextUpdater

        SupplierSetup setupModel ->
            SupplierSetup <| SupplierSetupPage.updateModelContext setupModel contextUpdater

        SupplierTasks tasksModel ->
            SupplierTasks <| SupplierTasksPage.updateModelContext tasksModel contextUpdater

        SupplierSettings settingsModel ->
            SupplierSettings <| SupplierSettingsPage.updateModelContext settingsModel contextUpdater

        GoogleAuthCallback googleAuthCallbackModel ->
            GoogleAuthCallback <| GoogleAuthCallbackPage.updateModelContext googleAuthCallbackModel contextUpdater

        Privacy _ ->
            model

        NotFound _ ->
            model


contextFromModel : AppInitModel -> Context.Context
contextFromModel model =
    case model of
        PublicHome publicHomeModel ->
            PublicHomePage.getContext publicHomeModel

        AdminHome adminHomeModel ->
            AdminHomePage.getContext adminHomeModel

        AdminEnquiries adminEnquiriesModel ->
            AdminEnquiriesPage.getContext adminEnquiriesModel

        AdminSignIn signInModel ->
            AdminSignInPage.getContext signInModel

        AdminSuppliers adminsuppliersModel ->
            AdminSuppliersPage.getContext adminsuppliersModel

        SupplierSignIn signInModel ->
            SupplierSignInPage.getContext signInModel

        SupplierSignUp signUpModel ->
            SupplierSignUpPage.getContext signUpModel

        SupplierSetup setupModel ->
            SupplierSetupPage.getContext setupModel

        SupplierTasks tasksModel ->
            SupplierTasksPage.getContext tasksModel

        SupplierSettings settingsModel ->
            SupplierSettingsPage.getContext settingsModel

        GoogleAuthCallback googleAuthCallbackModel ->
            GoogleAuthCallbackPage.getContext googleAuthCallbackModel

        Privacy context ->
            context

        NotFound context ->
            context


updateWithEffect :
    (subModel -> Model)
    -> (subMsg -> Msg)
    -> Effect.Handler subModel effect Msg
    -> Effect.Model subModel subMsg effect
    -> ( Model, Cmd Msg )
updateWithEffect toModel toMsg effectHandler ( subModel, subCmd, effect ) =
    let
        ( subModelWithEffect, effectCmd ) =
            Effect.evaluate effectHandler subModel effect
    in
    ( toModel subModelWithEffect, Cmd.batch [ Cmd.map toMsg subCmd, effectCmd ] )


adminHomePageEffectHandler : Effect.Handler AdminHomePage.Model AdminHomePage.Effect Msg
adminHomePageEffectHandler model effect =
    case effect of
        AdminHomePage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.AdminSignInDetached
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminHomePage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        AdminHomePage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (AdminHomePage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminHomePage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


adminEnquiriesPageEffectHandler : Effect.Handler AdminEnquiriesPage.Model AdminEnquiriesPage.Effect Msg
adminEnquiriesPageEffectHandler model effect =
    case effect of
        AdminEnquiriesPage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.AdminSignInDetached (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminEnquiriesPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        AdminEnquiriesPage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (AdminEnquiriesPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminEnquiriesPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


adminSuppliersPageEffectHandler : Effect.Handler AdminSuppliersPage.Model AdminSuppliersPage.Effect Msg
adminSuppliersPageEffectHandler model effect =
    case effect of
        AdminSuppliersPage.SignInEffect ->
            let
                updatedModel =
                    Context.sessionToContext updatedSession
                        |> AdminSuppliersPage.updateModelContext model
                        |> AdminSuppliersPage.setModelSuppliersLoading

                ( sessionCmd, updatedSession ) =
                    Session.grant Session.AdminSignInDetached
                        (SessionAuthorized
                            (Just (Cmd.map GotAdminSuppliersMsg AdminSuppliersPage.makeSuppliersRequest))
                        )
            in
            ( updatedModel, sessionCmd )

        AdminSuppliersPage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (AdminSuppliersPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminSuppliersPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


adminSignInPageEffectHandler : Effect.Handler AdminSignInPage.Model AdminSignInPage.Effect Msg
adminSignInPageEffectHandler model effect =
    case effect of
        AdminSignInPage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.AdminSignIn
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminSignInPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        AdminSignInPage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (AdminSignInPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> AdminSignInPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


supplierSignInPageEffectHandler : Effect.Handler SupplierSignInPage.Model SupplierSignInPage.Effect Msg
supplierSignInPageEffectHandler model effect =
    case effect of
        SupplierSignInPage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignIn
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSignInPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        SupplierSignInPage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (SupplierSignInPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSignInPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


supplierSetupEffectHandler : Effect.Handler SupplierSetupPage.Model SupplierSetupPage.Effect Msg
supplierSetupEffectHandler model effect =
    case effect of
        SupplierSetupPage.SupplierSignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignIn
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSetupPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        SupplierSetupPage.SupplierSigningOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (SupplierSetupPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSetupPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


supplierTasksEffectHandler : Effect.Handler SupplierTasksPage.Model SupplierTasksPage.Effect Msg
supplierTasksEffectHandler model effect =
    case effect of
        SupplierTasksPage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignInDetached
                        (SessionAuthorized
                            (Just
                                (Cmd.map GotSupplierTasksMsg
                                    (SupplierTasksPage.makeSupplierTasksRequest model.id)
                                )
                            )
                        )

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierTasksPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        SupplierTasksPage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (SupplierTasksPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierTasksPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        SupplierTasksPage.AccountSetupRoute ->
            ( model
            , Nav.pushUrl model.context.navKey
                (Route.routeToString (Route.SupplierRoute Route.SupplierSetup))
            )

        SupplierTasksPage.SettingsRouteEffect maybeSetting ->
            ( model
            , Nav.pushUrl model.context.navKey
                (Route.routeToString (Route.SupplierRoute (Route.SupplierSettings model.id maybeSetting)))
            )


supplierSettingsEffectHandler : Effect.Handler SupplierSettingsPage.Model SupplierSettingsPage.Effect Msg
supplierSettingsEffectHandler model effect =
    case effect of
        SupplierSettingsPage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignInDetached
                        (SessionAuthorized
                            Nothing
                        )

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSettingsPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        SupplierSettingsPage.SignOutEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.destroy (SupplierSettingsPage.getContext model).session SessionDestroyed

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSettingsPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


supplierSignUpPageEffectHandler : Effect.Handler SupplierSignUpPage.Model SupplierSignUpPage.Effect Msg
supplierSignUpPageEffectHandler model effect =
    case effect of
        SupplierSignUpPage.SignUpEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignUp
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSignUpPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        SupplierSignUpPage.SignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignIn
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> SupplierSignUpPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


googleAuthCallbackEffectHandler : Effect.Handler GoogleAuthCallbackPage.Model GoogleAuthCallbackPage.Effect Msg
googleAuthCallbackEffectHandler model effect =
    case effect of
        GoogleAuthCallbackPage.SupplierSignUpEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignUp
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> GoogleAuthCallbackPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )

        GoogleAuthCallbackPage.SupplierSignInEffect ->
            let
                ( sessionCmd, updatedSession ) =
                    Session.grant Session.SupplierSignIn
                        (SessionAuthorized Nothing)

                updatedModel =
                    Context.sessionToContext updatedSession |> GoogleAuthCallbackPage.updateModelContext model
            in
            ( updatedModel, sessionCmd )


view : Model -> Browser.Document Msg
view m =
    let
        viewPageWithMsg page toMsg config =
            let
                { title, body } =
                    Page.view page config
            in
            { title = title
            , body = List.map (Styled.map toMsg) body |> List.map Styled.toUnstyled
            }

        viewPage body title =
            { title = title, body = [ Styled.toUnstyled body ] }
    in
    case m of
        AppInit (PublicHome model) ->
            viewPageWithMsg
                Page.Home
                GotPublicHomeMsg
                (PublicHomePage.view model)

        AppInit (AdminHome model) ->
            viewPageWithMsg
                Page.AdminHome
                GotAdminHomeMsg
                (AdminHomePage.view
                    model
                )

        AppInit (AdminEnquiries model) ->
            viewPageWithMsg
                Page.AdminEnquiries
                GotAdminEnquiriesMsg
                (AdminEnquiriesPage.view model)

        AppInit (AdminSuppliers model) ->
            viewPageWithMsg
                Page.AdminSuppliers
                GotAdminSuppliersMsg
                (AdminSuppliersPage.view model)

        AppInit (AdminSignIn model) ->
            viewPageWithMsg Page.AdminSignIn GotAdminSignInMsg (AdminSignInPage.view model)

        AppInit (SupplierSignIn model) ->
            viewPageWithMsg Page.SignIn GotSupplierSignInMsg (SupplierSignInPage.view model)

        AppInit (SupplierSignUp model) ->
            viewPageWithMsg Page.SignUp GotSupplierSignUpMsg (SupplierSignUpPage.view model)

        AppInit (SupplierSetup model) ->
            viewPageWithMsg Page.SupplierSetup GotSupplierSetupMsg (SupplierSetupPage.view model)

        AppInit (SupplierTasks model) ->
            viewPageWithMsg Page.SupplierTasks GotSupplierTasksMsg (SupplierTasksPage.view model)

        AppInit (SupplierSettings model) ->
            viewPageWithMsg Page.SupplierSettings GotSupplierSettingsMsg (SupplierSettingsPage.view model)

        AppInit (GoogleAuthCallback model) ->
            viewPageWithMsg Page.GoogleAuthCallback GotAuthCallbackMsg (GoogleAuthCallbackPage.view model)

        AppInit (Privacy _) ->
            PrivacyPage.view

        AppInit (NotFound context) ->
            NotFoundPage.view context

        AppError ->
            AppErrorPage.view
